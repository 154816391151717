/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

          $(window).on('load', function() {
            
              if( $('.site-header').hasClass('video-header') ) {

                  var vidExists = $('.site-header .videobg-hide-controls').children('iframe').length;

                  if (typeof player == 'undefined' && vidExists ) {
                      var player = new Vimeo.Player($('.site-header .videobg-hide-controls').children('iframe'));
                  }
                  

                  var initialIframe = $('.videobg-hide-controls').children('iframe');

                  var isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));

                  if( !isTouch && initialIframe.length > 0 )
                  {
                      player.ready().then(function() {
                          
                          player.setVolume(0);
                          player.play().then(function() {

                              setTimeout(function() {
                                  $("body").removeClass('loading-header');
                              }, 500);

                              player.on('timeupdate', function(data) {
                                  if (data.percent >= 0.95) {
                                      player.unload().then(function() {
                                          player.setVolume(0);
                                          // $("body").removeClass('playing-video');
                                          // $('.site-header').addClass('video-header-overlay');
                                      });
                                  }
                              });

                          });
                      }).catch(function(error) {
                          console.log(error);
                      });

                  } else {
                      setTimeout(function() {
                          $("body").removeClass('loading-header');
                      }, 500);
                  }
              }
          });

          $('.header-video-play-link').on('click', function() {

              if (typeof player == 'undefined') {
                
                  var player = new Vimeo.Player($('.site-header .videobg-hide-controls').children('iframe'));

                  
              }

              if (typeof src == 'undefined') {
                  var src = player.element.src;
                  
              }

              var isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));

              if( !isTouch )
              {

                player.on('play', function() {
                    
                    $('body').addClass('playing-video');
                    $('.site-header').removeClass('video-header-overlay');
                    player.setVolume(1);
                  });
        
                player.on('timeupdate', function(data) {
                if (data.percent >= 0.95) {
                    player.unload().then(function() {
                        
                        player.setVolume(0);
                        $("body").removeClass('playing-video');
                        $('.site-header').addClass('video-header-overlay');
                    });
                }
                });

                player.on('pause', function() {
                    player.setVolume(0);
                    $("body").removeClass('playing-video');
                    $('.site-header').addClass('video-header-overlay');        
                });
        
                  var playerButton = $('.header-video-play-link');
                  var navigation = $('.header-navigation-wrapper');
                  var videoCaption = $('.video-caption-wrapper');
                  var elements;

                  player.unload();
                  player.play();

              } else if (isTouch) {
                  var video = $('.videobg-hide-controls').children('iframe');
                  var content = [];
                  var configuration = [];
                  $.featherlight({iframe: video[0].src, iframeMaxWidth: '100%', iframeWidth: 500,
                      iframeHeight: 300});
              }

          });

          function videobgEnlarge() {
              var timeoutId;
              var $videoBgAspect = $(".videobg-aspect");
              var $videoBgWidth = $(".videobg-width");
              var videoAspect = $videoBgAspect.outerHeight() / $videoBgAspect.outerWidth();

              windowAspect = ($(window).height() / $(window).width());
              if (windowAspect > videoAspect) {
                  $videoBgWidth.width((windowAspect / videoAspect) * 100 + '%');
              } else {
                  $videoBgWidth.width(100 + "%");
              }
          }

          function displayHeaderVideo(video, player) {
              var isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
              var elements;
              var navigation = $('.header-navigation-wrapper');
              var videoCaption = $('.video-caption-wrapper');

              if( isTouch )
              {
                  $.featherlight({iframe: video[0].src, iframeMaxWidth: '100%', iframeWidth: 500, iframeHeight: 300});
              } else {
                  $(elements).add(navigation).add(videoCaption).fadeOut(400);
                  $('.site-header').removeClass('video-header-overlay');
                  setTimeout(function() {
                      player.setVolume(1);
                  }, 500);
              }
          }

          videobgEnlarge();

          $(window).on('resize', function() {
              if( typeof timeoutId !== 'undefined' )
              {
                  clearTimeout(timeoutId);
              }
              timeoutId = setTimeout(videobgEnlarge, 100);
          });

          $(window).on('resize load', function() {
              var mainwrapper = $('.wrap');
              var footerHeight = $('.site-footer').outerHeight(true);
              var windowInnerHeight = $(window).innerHeight();

              if( $(window).innerHeight() >= footerHeight) {
                  $('body').addClass('hundred-proof-sticky-footer');
                  $('body').css('margin-bottom', footerHeight);
              } else {
                  if($('body').hasClass('hundred-proof-sticky-footer')) {
                      $('body').removeClass('hundred-proof-sticky-footer');
                      $('body').css('margin-bottom', 0);
                  }
              }
          });
          
          $('.nav-main-dropdown-icon').on('click', function() {
              if($(this).parent().hasClass('nav-item-has-children'))
              {
                  $(this).parent().toggleClass('list-item-active');
              }
          });

          $('.mobile-menu-close').on('click', function() {
              $('html, body').removeClass("nav-open");
          });

          $('#menu-icon').on('click', function() {
              $('html, body').addClass("nav-open");
          });
      },
      finalize: function() {
  
        AOS.init({
        offset: 275,
        duration: 750,
        easing: 'ease-in-out-cubic',
        delay: 100,
        disable: 'mobile'
        });

        $('.block-contact-form-wrapper').css('opacity', '1');
  
      }
    },
    // Home page
    'home': {
      init: function() {

          $(window).on('load', function() {

              // var portfolioIframe = $('.home-portfolio-feature-wrapper .video-bg-hide-controls').children('iframe');

              // var portfolioPlayer = new Vimeo.Player($('.home-portfolio-iframe-wrapper').children('iframe'));
              // portfolioPlayer.ready().then(function() {
              //     portfolioPlayer.setVolume(0);
              // });
              //
              // $('.home-portfolio-play-link').on('click', function() {
              //     var portfolioPlayer = new Vimeo.Player($('.home-portfolio-iframe-wrapper').children('iframe'));
              //     var isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
              //     if( !isTouch )
              //     {
              //         portfolioPlayer.play().then(function() {
              //             $('.home-portfolio-feature-wrapper .video-caption-wrapper').addClass('playing-video');
              //             portfolioPlayer.on('pause', function(data) {
              //                 portfolioPlayer.unload().then(function() {
              //                     portfolioPlayer.setVolume(0);
              //                     portfolioPlayer.play();
              //                     $('.home-portfolio-feature-wrapper .video-caption-wrapper').removeClass('playing-video');
              //                 });
              //             });
              //             setTimeout(function() {
              //                 portfolioPlayer.setVolume(1);
              //             }, 1500);
              //         });
              //
              //         // player.on('ended', function(data) {
              //         //     player.unload().then(function() {
              //         //         player.setVolume(0);
              //         //         $("body").removeClass('playing-video');
              //         //     });
              //         // });
              //
              //     } else if (isTouch) {
              //         var video = new Vimeo.Player($('.home-portfolio-iframe-wrapper').children('iframe'));
              //         var content = [];
              //         var configuration = [];
              //         $.featherlight({iframe: video[0].src, iframeMaxWidth: '100%', iframeWidth: 500,
              //             iframeHeight: 300});
              //     }
              //
              // });

          });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'services': {
      init: function() {
          $('.service-block-introduction-wrapper').click(function() {
              var block = $(this).attr('serviceBlockID');
              var contentBlock = $('.service-content-block-' + block);

              if(contentBlock)
              {
                  contentBlock.toggleClass('show-content-block');
              }
          });

          $('.service-block-icon').click(function() {
            var index = $(this).attr('index');
            var block = $('.service-content-block-' + index);
            block.removeClass('show-content-block');
          });
      }
    },
    'team': {
      init: function() {

        var teamSlider = $('.team-slider');
        var teamSliderNext = $('.team_next');
        var teamSliderPrev= $('.team_prev');

        teamSlider.owlCarousel({
           items: 1,
           loop: false,
           rewind: true,
           dots: false,
           center: true,
            smartSpeed: 600,
            responsive: {
              575: {
                  stagePadding: 75
              },
              678: {
                  stagePadding: 100
              },
              768: {
                  stagePadding: 80
              },
              860: {
                  stagePadding: 100
              },
              1013: {
                  stagePadding: 90
              },
              1366: { // Desktop
                  stagePadding: 160
              },
              1440: { //
                  stagePadding: 180
              },
              1920: { // 1920 x 1080
                  stagePadding: 400
              },
              2560: {
                  stagePadding: 550
              }
            }
        });

        /**
        * Custom Navigation Clicks
        */
        teamSliderNext.click(function(){
          teamSlider.trigger('next.owl.carousel');
          });
          teamSliderPrev.click(function(){
          teamSlider.trigger('prev.owl.carousel');
        });
      }
    },
    'single_portfolio': {
      init: function() {

          var isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));

          $('.portfolio-element').on('click', function() {

              /**
               * Header Iframe Stuff
               */
              var header = $('.site-header');
              var headerIframe = $('.videobg-hide-controls').children('iframe');
              var headerNavigation = $('.header-navigation-wrapper');
              var headerCaptionWrapper = $('.video-caption-wrapper');

              /**
               * New Video Iframe Stuff
               */
              var videoIframe = $(this).attr('video-frame');
              var videoCaption = $(this).attr('video-caption');

              /**
               * Return outerHTML for the first element in a jQuery object,
               * or an empty string if the jQuery object is empty;
               */
              jQuery.fn.outerHTML = function() {
                  return (this[0]) ? this[0].outerHTML : '';
              };

             var playLink = $('span.header-video-play-link').outerHTML();

             headerIframeReplace(header, headerIframe, headerCaptionWrapper, headerNavigation, videoIframe, videoCaption, playLink);

              function headerIframeReplace(header, headerIframe, headerCaption, headerNav, newIframe, newCaption, playLink) {

                  if (!isTouch) {
                      // console.log(headerIframe);
                      // var player = new Vimeo.Player(headerIframe);
                      var objects;

                      $("html, body").addClass('loading-video no-scroll');

                      $("html, body").animate({scrollTop: 0}, "slow", function () {

                          // uncommented, didn't think necessary - header.addClass'loading-video');
                          headerIframe.replaceWith(newIframe);

                          /*** Redefine Header Iframe To new Iframe. ***/
                          headerIframe = $('.videobg-hide-controls').children('iframe');

                          var player = new Vimeo.Player(headerIframe);

                          player.ready().then(function() {
                              player.setVolume(0);
                              /*** Replace Caption ****/
                              var newCaptionHtml = newCaption + playLink;
                              player.play().then(function () {

                                  $('.video-caption-content-wrapper').html(newCaptionHtml);

                                  // /**
                                  //  * Fade new elements in
                                  //  */
                                  // var fadeInElements;
                                  // $(header).removeClass('loading-video');
                                  // if (!$('.site-header').hasClass('video-header-overlay')) {
                                  //     $('.site-header').addClass('video-header-overlay');
                                  // }

                                  // $(fadeInElements).add(headerIframe).add(headerCaption).add(headerNav).fadeIn(3000);

                                  /**
                                   * Redefine event listener
                                   */

                                  var navigation = $('.header-navigation-wrapper');
                                  var videoCaption = $('.video-caption-wrapper');
                                  var elements;

                                  $('.header-video-play-link').on('click', function() {

                                    if (typeof player == 'undefined') {
                                        var player = new Vimeo.Player($('.site-header .videobg-hide-controls').children('iframe'));
                                    }
                      
                                    if (typeof src == 'undefined') {
                                        var src = player.element.src;
                                    }
                      
                                    var isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
                      
                                    if( !isTouch )
                                    {
                      
                                      player.on('play', function() {
                                          $('body').addClass('playing-video');
                                          $('.site-header').removeClass('video-header-overlay');
                                          player.setVolume(1);
                                        });
                              
                                      player.on('timeupdate', function(data) {
                                      if (data.percent >= 0.95) {
                                          player.unload().then(function() {
                                              player.setVolume(0);
                                              $("body").removeClass('playing-video');
                                              $('.site-header').addClass('video-header-overlay');
                                          });
                                      }
                                      });
                      
                                      player.on('pause', function() {
                                          player.setVolume(0);
                                          $("body").removeClass('playing-video');
                                          $('.site-header').addClass('video-header-overlay');        
                                      });
                              
                                        var playerButton = $('.header-video-play-link');
                                        var navigation = $('.header-navigation-wrapper');
                                        var videoCaption = $('.video-caption-wrapper');
                                        var elements;
                      
                                        player.unload();
                                        player.play();
                      
                                    } else if (isTouch) {
                                        var video = $('.videobg-hide-controls').children('iframe');
                                        var content = [];
                                        var configuration = [];
                                        $.featherlight({iframe: video[0].src, iframeMaxWidth: '100%', iframeWidth: 500,
                                            iframeHeight: 300});
                                    }
                      
                                });
                      

                                //   $('.header-video-play-link').on('click', function() {

                                //       var player = new Vimeo.Player($('.videobg-hide-controls').children('iframe'));
                                //       var isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));

                                //       if( !isTouch )
                                //       {
                                //           var playerButton = $('.header-video-play-link');
                                //           var navigation = $('.header-navigation-wrapper');
                                //           var videoCaption = $('.video-caption-wrapper');
                                //           var elements;

                                //           $('body').addClass('playing-video');
                                //           $('.site-header').removeClass('video-header-overlay');

                                //           player.setVolume(1);

                                //           player.on('timeupdate', function(data) {
                                //               if (data.percent >= 0.95) {
                                //                   player.unload().then(function() {
                                //                       player.setVolume(0);
                                //                       $("body").removeClass('playing-video');
                                //                       $('.site-header').addClass('video-header-overlay');
                                //                   });
                                //               }
                                //           });

                                //           player.on('pause', function(data) {
                                //               player.unload().then(function() {
                                //                   player.setVolume(0);
                                //                   $("body").removeClass('playing-video');
                                //                   $('.site-header').addClass('video-header-overlay');
                                //               });
                                //           });

                                //       } else if (isTouch) {
                                //           var video = $('.videobg-hide-controls').children('iframe');
                                //           var content = [];
                                //           var configuration = [];
                                //           $.featherlight({iframe: video[0].src, iframeMaxWidth: '100%', iframeWidth: 500,
                                //               iframeHeight: 300});
                                //       }

                                //   });

                                  if( $('html, body').hasClass('playing-video') )
                                  {
                                      $('html, body').removeClass('playing-video');
                                      if (!$('.site-header').hasClass('video-header-overlay')) {
                                          $('.site-header').addClass('video-header-overlay');
                                      }
                                  }
                              });
                          });
                      });

                      setTimeout(function() {
                          $("html, body").removeClass('loading-video no-scroll');
                      }, 3000);

                  } else {
                      // console.log('Header Iframe: ' + headerIframe[0].src);
                      // console.log('New Iframe: ' + newIframe);
                      var regex = /<iframe.*?src=['"](.*?)['"]/;
                      var newIframeSrc = regex.exec(newIframe)[1];

                      if (newIframeSrc) {
                          $.featherlight({
                              iframe: newIframeSrc, iframeMaxWidth: '100%', iframeWidth: 500,
                              iframeHeight: 300
                          });
                      }
                  }
              }
          });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
